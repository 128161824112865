import React, { Component } from 'react';
// import { withStyles } from '@material-ui/core/styles';
import ResponsivePlayer from '../Components/ResponsivePlayer.js'
import PortfolioCard from '../Components/PortfolioCard.js'


const styles = theme => ({
});

class Portfolio extends Component {

  state=
  {
    vidUrl:"",
    isPlaying:false,
    selectedProject:""
  }
  componentDidMount()
  {

  }

  playVideo=(url,project)=>
  {
    this.setState({vidUrl:url,isPlaying:true,selectedProject:project})
  }

  closePlayer=()=>{this.setState({vidUrl:"",isPlaying:false,selectedProject:""})}


  renderPortfolioContent=(data)=>
  {
      return(
        <>
          {        
            data.projects.map((projects)=>{ 
              var projectImage = 'images/portfolio/'+projects.image;
              return(
                <div key={projects.title} className="columns portfolio-item">
                 <div className="item-wrap">
                  <PortfolioCard projects={projects} image={projectImage} url={projects.url} playVideo={this.playVideo.bind(this)} />
                </div>
              </div>
            )})
          } 
        </>
      )
  }


  render() {
    const {classes} = this.props;
    
    return (
      <section id="portfolio">
      <div className="row">
      <ResponsivePlayer url={this.state.vidUrl} isPlaying={this.state.isPlaying} closePlayer={this.closePlayer.bind(this)} project={this.state.selectedProject} />
         <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {this.props.data&&this.renderPortfolioContent(this.props.data)}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Portfolio;
