import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

// import ResponsivePlayer from '../Components/ResponsivePlayer'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PortfolioCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={()=>props.playVideo(props.url,props.projects)}>
      <CardHeader
        title={props.projects.title}
        //subheader="September 14, 2016"
      />
      <CardMedia
        className={classes.media}
        image={props.image}
        title={props.projects.title}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.projects.category}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>

      </CardActions>
    </Card>
  );
}
